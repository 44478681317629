import React from 'react'
import '../../components/Accordion/Accordion.css'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../components/Accordion/Accordion'
import AccordionTemplate from '../../components/Services/AccordionTemplate'
import Content from '../../../content/accordion/predbezna-opatreni'
import { graphql } from 'gatsby'
import { H2Blue, H3Blue, UnderlineYellow } from '../../components/Typography'
const Proadvokaty = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <p style={{ marginTop: '5.8rem' }}>
          Jste v situaci, kdy u Vašeho klienta probíhá komplikovaná daňová
          kontrola, případně Váš klient bude podávat správní žalobu ve vazbě na
          spor s finančním úřadem? Jelikož máme s uvedenými případy bohaté
          zkušenosti, nabízíme Vám pomoc v těchto oblastech:
        </p>
        <br />
        <br />
        <H3Blue>
          1. Zastupování v procesu vedení kontroly i následného odvolání
        </H3Blue>
        <UnderlineYellow />
        <p>{frontmatter.description}</p>
        <p style={{ marginTop: '5.8rem' }}>
          Vedení kontroly se správcem daně může být komplikované i časově
          náročné zároveň. Spolupráce s námi může Vám i Vašim klientům přinést
          kromě nezávislého pohledu externího daňového poradce i značnou časovou
          úsporu.
        </p>
        <p style={{ marginTop: '2.8rem' }}>
          V minulých letech jsme zastupovali daňové subjekty téměř ve všech
          daňových oblastech (DPH, DPFO, DPPO i spotřební daně). Využijte našeho
          spojení know-how daňových poradců se znalostí procesní problematiky
          zpracování odvolání i následných správních žalob.
        </p>
        <br />
        <div className="Accordion-single Accordion-single-text">
          <a
            href="https://www.danovekontroly.cz/kontroly-z-pohledu-fu/postup-k-odstraneni-pochybnosti/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1>Postup k odstranění pochybností</h1>
          </a>
        </div>
        <br />
        <div className="Accordion-single Accordion-single-text">
          <a
            href="https://www.danovekontroly.cz/kontroly-z-pohledu-fu/danova-kontrola/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1>Daňová kontrola</h1>
          </a>
        </div>
        <H3Blue style={{ marginTop: '5.8rem' }}>
          2. Zpracování správních žalob, kasační stížnosti
        </H3Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '2.8rem' }}>
          Máme zkušenosti se zpracováním žalob, a to jak žalob proti rozhodnutí
          správního orgánu (§ 65 s.ř.s), tak žalob na ochranu před nečinností (§
          79 s.ř.s.) i proti nezákonnému zásahu (§ 82 s.ř.s.). Ne jednou jsme
          také klientům asistovali při sestavení kasačních stížností Nejvyššímu
          správnímu soudu.
        </p>
        <div className="Accordion-single Accordion-single-text">
          <a
            href="https://www.danovekontroly.cz/zaloby-kasace/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1>Žaloby a kasace</h1>
          </a>
        </div>
        <br />
        <H3Blue>3. Zajišťovací příkazy</H3Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '2.8rem' }}>
          Obdržel Váš klient zajišťovací příkaz a potřebuje se proti němu účinně
          bránit? Neváhejte využít know-how a bohaté zkušenosti našich daňových
          poradců.
        </p>
        <div className="Accordion-single Accordion-single-text">
          <a
            href="https://www.danovekontroly.cz/zajistovaci-prikazy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1>Zajišťovací příkazy</h1>
          </a>
        </div>
        <br />
        <br />
        <H3Blue>4. Návrh na předběžné opatření</H3Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '2.8rem' }}>
          Pomůžeme Vám sestavit návrh na předběžné opatření. Ze zkušenosti víme,
          že pokud se klient rozhodne tento návrh podat, jedná se o vážnou a
          velice akutní záležitost. Jsme si vědomi potřeb vašich i vašich
          klientů a kromě profesionálního přístupu a celé řady zkušeností
          nabízíme také časovou pružnost a kvalitu výsledků.
        </p>
        <Accordion
          items={[
            {
              title: Content.predbeznaOpatreni.title,
              description: <AccordionTemplate {...Content.predbeznaOpatreni} />
            }
          ]}
        />
      </div>
      <br />
      <br />
    </Layout>
  )
}

export default Proadvokaty

export const pageQuery = graphql`
  query Proadvokaty {
    page: markdownRemark(frontmatter: { slug: { eq: "pro-advokaty" } }) {
      ...Meta
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
