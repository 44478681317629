const topicSlug = {
  predbeznaOpatreni: 'predbezna-opatreni/'
}

export const Content = {
  predbeznaOpatreni: {
    title: 'Předběžná opatření',
    slug: topicSlug.predbeznaOpatreni,
    description: `Předběžné opatření slouží k ochraně práv dítěte a zajištění jeho bezpečí. Používá se pouze...
`
  }
}
export default Content
